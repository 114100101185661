import {
    ADD_SUPERVISER_REQUEST,
     ADD_SUPERVISER_SUCCESS,
     ADD_SUPERVISER_FAIL,
     GET_SUPERVISER_REQUEST,
     GET_SUPERVISER_SUCCESS,
     GET_SUPERVISER_FAIL,
     CHANGE_SUPERVISOR_STATUS_REQUEST,
     CHANGE_SUPERVISOR_STATUS_SUCCESS,
  CHANGE_SUPERVISOR_STATUS_FAIL,
  OPEN_EDIT_SUPERVISOR,
} from './../constant/superviserConstants';
     
     export const supervisorReducer = (state = { supervisor: {} }, { type, payload }) => {
         switch (type) {
     
             case ADD_SUPERVISER_REQUEST: return {
                 ...state,
                 loading: true
             }
             case  ADD_SUPERVISER_SUCCESS: return {
                 ...state,
                 addedSupervisor: payload,
                 addSupervisor:true,
                 loading: false
             }
     
             case ADD_SUPERVISER_FAIL: return {
                 ...state,
                 error: payload,
                 loading: false
             }
             case GET_SUPERVISER_REQUEST: return {
                ...state,
                loading: true
            }
            case  GET_SUPERVISER_SUCCESS: return {
                ...state,
               supervisor: payload,
                loading: false
            }
    
            case GET_SUPERVISER_FAIL: return {
                ...state,
                error: payload,
                loading: false
            }
            case CHANGE_SUPERVISOR_STATUS_REQUEST: return {
                ...state,
                loading: true
            }
            case CHANGE_SUPERVISOR_STATUS_SUCCESS: return {
                ...state,
                changedStatus: payload,
                loading: false
            }
    
            case CHANGE_SUPERVISOR_STATUS_FAIL: return {
                ...state,
                error: payload,
                loading: false
            }
             
    case OPEN_EDIT_SUPERVISOR:
      return {
        ...state,
        supervisorDetails: payload,
        loading: true,
      };

    default:
      return state;
     }
};
