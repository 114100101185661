export const ADD_BANNER_REQUEST = "ADD_BANNER_REQUEST"
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS"
export const ADD_BANNER_FAIL = "ADD_BANNER_FAIL"

export const EDIT_BANNER_REQUEST = "ADD_BANNER_REQUEST"
export const EDIT_BANNER_SUCCESS = "ADD_BANNER_SUCCESS"
export const EDIT_BANNER_FAIL = "ADD_BANNER_FAIL"

export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST"
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS"
export const GET_BANNER_FAIL = "GET_BANNER_FAIL"

export const GET_BANNER_TYPES_REQUEST = "GET_BANNER_TYPES_REQUEST"
export const GET_BANNER_TYPES_SUCCESS = "GET_BANNER_TYPES_SUCCESS"
export const GET_BANNER_TYPES_FAIL = "GET_BANNER_TYPES_FAIL"

export const CHANGE_BANNER_STATUS_REQUEST = "CHANGE_BANNER_STATUS_REQUEST"
export const CHANGE_BANNER_STATUS_SUCCESS = "CHANGE_BANNER_STATUS_SUCCESS"
export const CHANGE_BANNER_STATUS_FAIL = "CHANGE_BANNER_STATUS_FAIL"


export const OPEN_EDIT_BANNER = "OPEN_EDIT_BANNER"
